/* @ngInject */
function ProgramasConfig($stateProvider) {
  $stateProvider
    .state('app.programas', {
      url: '/programas',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/programas/programas.html',
          controller: 'ProgramasCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.PROGRAMAS.PAGE_TITLE');
        }
      }
    });
}

export default ProgramasConfig;

