/* @ngInject */
class Jul17peabCtrl {
  constructor($scope, $cookies, $log, Promos) {

    this.activePromo = false;

    let cookie = $cookies.get('jul17peab');

    if ( cookie !== 'true' ){
      Promos.jul17peab().userIsAllowed().$promise.then( (result) => {
        this.activePromo = result.is_allowed;
      });

      $scope.$on('$product:added', () => {
        Promos.jul17peab().userIsAllowed().$promise.then( (result) => {
          this.activePromo = result.is_allowed;
        })
      });
    }

    $scope.$on('$jul17peab:sent', () => {
      if ( $cookies.get('jul17peab') === 'true' ){
        this.activePromo = false;
      }
    });
  }
}

let Jul17peab = {
  replace: true,
  transclude: true,
  controller: Jul17peabCtrl,
  templateUrl: 'addons/components/promo.jul17peab.component.html'
};

export default Jul17peab;

