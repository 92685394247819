'use strict';

function smartXeditable($timeout, $log){
  'ngInject';

  function link (scope, element, attrs, ngModel) {

    var defaults = {
      // display: function(value, srcData) {
      //     ngModel.$setViewValue(value);
      //     // scope.$apply();
      // }
    };

    var inited = false;

    var initXeditable = function() {

      var options = scope.options || {};
      var initOptions = angular.extend(defaults, options);

      // $log.log(initOptions);
      element.editable('destroy');
      element.editable(initOptions);
    }

    scope.$watch("options", function(newValue) {

      if(!newValue) {
        return false;
      }

      initXeditable();

      // $log.log("Options changed...");

    }, true);

  }

  return {
    restrict: 'A',
    require: "ngModel",
    scope: {
      options: "="
    },
    link: link

  }
}

export default smartXeditable;