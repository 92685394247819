/* @ngInject */
class ResetPasswordCtrl {
  constructor($http, $location, AppConstants, JWT, $state) {

    this._$http = $http;
    this._AppConstants = AppConstants;
    this._JWT = JWT;
    this._$state = $state;

    this.account = {};

    this.errorMsg = '';

    if ($location.hash().trim().length <= 0) {
      this.errorMsg = 'Usuario desconocido.';
    }

    this.hash = $location.hash();
  }

  changePassword() {
    this.errorMsg = '';
    let url = `${this._AppConstants.api}/change-password/${this.hash}`;

    if(this.loginForm.$valid) {
      if (this.account.password != this.account.passwordConfirm) {
        this.errorMsg = 'La confirmación de la contraseña no coincide.';
        return;
      }

      this._$http({
        method: 'POST',
        url: url,
        data: {
          password: this.account.password
        },
        skipAuthorization: true
      })
        .then((response) => {
          if(response.data.type == 'success'){
            this.validateMsg = 'Su contraseña ha sido modificada, ya puede acceder con sus nuevos datos.';
          } else if(response.data.type == 'error'){
            this.errorMsg = response.data.msg;
          }else{
            this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
          }
        },() => {
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        })
    }else{
      this.loginForm.password.$dirty = true;
      this.loginForm.passwordConfirm.$dirty = true;
    }
  }
}

export default ResetPasswordCtrl;