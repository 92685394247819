/* @ngInject */
export default class Promos {

  constructor( AppConstants, $state, $resource ) {

    this._AppConstants = AppConstants;
    this._$state = $state;
    this._$resource = $resource;

    this.current = null;
  }

  jul17peab() {
    let url = `${this._AppConstants.api}/promos/jul17peab`;
    let opts = {
      isActive: {
        method: 'GET'
      },
      userIsAllowed: {
        url: `${url}/user-is-allowed`,
        method: 'GET'
      }
    };

    return this._$resource(url,{},opts);
  }

}