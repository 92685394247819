'use strict';

var $ = require('jquery');

function bootstrapTogglingForm(){

  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'app/SmartAdmin/directives/forms/bootstrap-validation/bootstrapTogglingForm.html',
    link: function(scope, form){
      form.bootstrapValidator({
        feedbackIcons : {
          valid : 'glyphicon glyphicon-ok',
          invalid : 'glyphicon glyphicon-remove',
          validating : 'glyphicon glyphicon-refresh'
        },
        fields : {
          firstName : {
            validators : {
              notEmpty : {
                message : 'The first name is required'
              }
            }
          },
          lastName : {
            validators : {
              notEmpty : {
                message : 'The last name is required'
              }
            }
          },
          company : {
            validators : {
              notEmpty : {
                message : 'The company name is required'
              }
            }
          },
          // These fields will be validated when being visible
          job : {
            validators : {
              notEmpty : {
                message : 'The job title is required'
              }
            }
          },
          department : {
            validators : {
              notEmpty : {
                message : 'The department name is required'
              }
            }
          },
          mobilePhone : {
            validators : {
              notEmpty : {
                message : 'The mobile phone number is required'
              },
              digits : {
                message : 'The mobile phone number is not valid'
              }
            }
          },
          // These fields will be validated when being visible
          homePhone : {
            validators : {
              digits : {
                message : 'The home phone number is not valid'
              }
            }
          },
          officePhone : {
            validators : {
              digits : {
                message : 'The office phone number is not valid'
              }
            }
          }
        }
      }).find('button[data-toggle]').on('click', function() {
        var $target = $($(this).attr('data-toggle'));
        // Show or hide the additional fields
        // They will or will not be validated based on their visibilities
        $target.toggle();
        if (!$target.is(':visible')) {
          // Enable the submit buttons in case additional fields are not valid
          form.data('bootstrapValidator').disableSubmitButtons(false);
        }
      });
    }

  }

}

export default bootstrapTogglingForm;