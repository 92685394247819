let componentsModule = angular.module('app.components',[]);

// Components
import ExampleComponent from './example.component';
componentsModule.component('exampleComponent', ExampleComponent);

import TusProductosComponent from './tus-productos.component';
componentsModule.component('tusProductos', TusProductosComponent);

import LanguageSelectorComponent from './language.component';
componentsModule.component('languageSelector', LanguageSelectorComponent);

import Jul17peabComponent from './promo.jul17peab.component';
componentsModule.component('jul17peabComponent', Jul17peabComponent);

export default componentsModule;