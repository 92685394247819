'use strict';

/* @ngInject */
class ActivitiesCtrl {
  constructor($scope, $log, Activities) {

    this.activeTab = 'default';
    this.currentActivityItems = [];

    // Getting different type of activites
    Activities.get((data) =>{

      this.activities = data.activities;

    });


    this.isActive = (tab) => {
      return this.activeTab === tab;
    };

    this.setTab = (activityType) => {
      this.activeTab = activityType;

      Activities.getbytype(activityType, (data) => {

        this.currentActivityItems = data.data;

      });

    };
  }
}

export default ActivitiesCtrl;