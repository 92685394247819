/* @ngInject */
class ProductosCtrl {
  constructor($q, $scope, $window, $state, $filter, PAGETITLE, JWT, Productos, Server) {


    this._$state = $state;
    this._$scope = $scope;
    this._JWT = JWT;
		this._Server = Server;
    this._$filter = $filter;
    this._$window = $window;
    this._$q = $q;

    this.pageTitle = [PAGETITLE];

    if (!this._JWT.userHasToken()) {
    	this._$state.go('login');
    }

    //Flags
    this.loadingDownload = false;

    this.data = {};

    this.marcas = {};
    this.products = [];
    this.noProductsFound = false;
    this.searchingProducts = false;

    this.account = {};
    this.sale_id = 0;

    this.showRegister = false;
    this.showWarranty = false;

    // Date options
    this.openDatepicker = function() {
      this.popupDatepicker.opened = true;
    };
    this.popupDatepicker = {
      opened: false
    };
    this.openWarrantyDatepicker = function() {
      this.popupWarrantyDatepicker.opened = true;
    };
    this.popupWarrantyDatepicker = {
      opened: false
    };
    this.dateOptions = {
    };
    this.format = 'dd/MM/yyyy';
  }

  $onInit() {
    this._$q.all({
      Brands: this._Server.brands().get().$promise,
      Me: this._Server.user().get().$promise,
      B3AffectedProducts: this._Server.bigThree().affectedProducts().$promise
    })
      .then( (res) => {
        const affectedProducts = res.B3AffectedProducts.data;

        this.data = res.Me;

        this.data.products.map( (product) => {
          product.b3_affected = false;
          angular.forEach(affectedProducts, (affected) => {
            if ( product.sale_id  == affected.venta_id ) {
              product.b3_affected = true;
            }
          })
        });

        this.marcas = res.Brands.data;
      });
  }

  showRegistration() {
    this.account = {};

  	this.showRegister = !this.showRegister;
    this.showWarranty = false;

    if (this.showRegister)
    {
      this.account.marca = null;
      this.account.familia = null;
      this.account.producto = null;
    }
  }

  showRegistrationWarranty(item) {
    this.account = {};

    if (this.sale_id == 0 || item.sale_id == this.sale_id) {
      this.showWarranty = !this.showWarranty;
    }

    this.showRegister = false;
    this.sale_id = item.sale_id;

    this.account.marca = this._$filter('filter')(this.marcas, { id : item.brand_id}, true)[0];
    this.familias = this.account.marca.families;

    this.account.familia = this._$filter('filter')(this.familias, { cod : item.family_cod}, true)[0];
    this.productos = this.account.familia.products;

    this.account.producto = this._$filter('filter')(this.productos, { model : item.model}, true)[0];
  }

  onSearchProducts() {
    this.noProductsFound = false;
    this.searchingProducts = true;
    this.products = [];
    this.account.selectedProduct = null;
    this._Server.dailySales().get({serial: this.account.serial, brand: this.account.marca.id}).$promise
      .then((res) => {
        this.searchingProducts = false;
        if (res.data.length === 0) {
          // error
          this.noProductsFound = true;
        } else {
          this.products = res.data;
        }
      });
  }

  register() {
    this.errorMsg = '';
    this.registerMsg = '';
    if(this.registerForm.$valid) {
      let modelCode = this.account.selectedProduct.model;
      // EXCEPCIONES
      if ( modelCode === 'SIAT921' ) {
        modelCode = 'SIAT911';
      }
      if ( modelCode === 'SIAT961' ) {
        modelCode = 'SIAT951';
      }

      let data = {
        serial_number: this.account.serial,
        brand: this.account.marca.id,
        family: this.account.selectedProduct.family_cod,
        product: modelCode,
        comprado: moment(this.account.comprado).format('YYYY-MM-DD')
      };
      this._Server.sales().register(data).$promise.then((response) => {
        console.log(response);
        if(response.type && response.type == 'error'){
          this.errorMsg = response.msg;

        } else if(response.type && response.type == 'success'){
          this.registerMsg = 'Producto registrado correctamente!';
          this.data.products = response.products;

        }else{
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        }
      },(error) => {
        this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
    } else {
      this.registerForm.marca.$dirty = true;
      this.registerForm.familia.$dirty = true;
      this.registerForm.producto.$dirty = true;
      this.registerForm.comprado.$dirty = true;
      this.registerForm.serial.$dirty = true;
    }
  }

  warranty() {
    this.errorMsg = '';
    this.warrantyMsg = '';
    if(this.warranyForm.$valid) {
      let data = {
        sale_id: this.sale_id,
        serial_number: this.account.serial,
        brand: this.account.marca.id,
        family: this.account.familia.cod,
        product: this.account.producto.model,
        comprado: moment(this.account.comprado).format('YYYY-MM-DD')
      };
      this._Server.warranties().register(data).$promise.then((response) => {
        console.log(response);
        if(response.type && response.type == 'error'){
          this.errorMsg = response.msg;

        } else if(response.type && response.type == 'success'){
          this.warrantyMsg = 'Garantia registrada correctamente!';
          this.data.products = response.products;

        }else{
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        }
      },(error) => {
        this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
    } else {
      this.warranyForm.marca.$dirty = true;
      this.warranyForm.familia.$dirty = true;
      this.warranyForm.producto.$dirty = true;
      this.warranyForm.comprado.$dirty = true;
      this.warranyForm.serial.$dirty = true;
    }
  }

  downloadWarranty(product) {
    let object = {
      sale_id : product.sale_id
    };

    this.loadingDownload = true;
    this._Server.sales().getWarrantyPDF(object).$promise.then( (data) => {
      let fileURL = URL.createObjectURL(data.response);
      this._$window.open(fileURL);
      this.loadingDownload = false;
    })
  }

}

export default ProductosCtrl;
