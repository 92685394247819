/**
 * Clase de entrada. En ocasiones puede cargarse antes que acaban de ejecutarse las tareas en el Run.
 * Aquí comprobaremos si hemos sincronizado la primera vez, si tenemos que bloquear por actualización...
 */
/* @ngInject */
class DashboardCtrl {
  constructor($q, $scope, $rootScope, $window, $state, $filter, JWT, Brands, Server) {

    this._$state = $state;
    this._$q = $q;
    this._JWT = JWT;
		this._Server = Server;
    this._$filter = $filter;
    this._$window = $window;
    this._$rootScope = $rootScope;

    this.marcas = {};

    if (!this._JWT.userHasToken()) {
    	this._$state.go('login');
    }

    //Flags
    this.loadingDownload = false;

    this.data = {};

    // Image options
    if (!this.data.avatar)
      this.myImage = '/images/profile-placeholder.jpg';
    else
      this.myImage = this.data.avatar;

		// Cargamos las noticias del feed
		this.feedSigmaNews = this.feedReflectaNews = [];

    // Cargamos datos de una
		$q.all({
			SigmaFeed: this._Server.feed().getSigma().$promise,
			ReflectaFeed: this._Server.feed().getReflecta().$promise
		}).then( (response) => {
			this.feedSigmaNews = response.SigmaFeed;
			this.feedReflectaNews = response.ReflectaFeed;
		});
  }

  $onInit() {

    this._$q.all({
      Brands: this._Server.brands().get().$promise,
      Me: this._Server.user().get().$promise,
      B3AffectedProducts: this._Server.bigThree().affectedProducts().$promise
    })
      .then( (res) => {
        const affectedProducts = res.B3AffectedProducts.data;

        this.data = res.Me;

        this.data.products.map( (product) => {
          product.b3_affected = false;
          angular.forEach(affectedProducts, (affected) => {
            if ( product.sale_id  == affected.venta_id ) {
              product.b3_affected = true;
            }
          })
        });

        this.marcas = res.Brands.data;
      });
  }


}

export default DashboardCtrl;
