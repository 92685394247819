/* @ngInject */
function ProductosConfig($stateProvider) {
  $stateProvider
    .state('app.promos', {
      url: '/promos',
      abstract: true,
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/promos/layout.html'
        }
      }
    })
    .state('app.promos.jul17peab', {
      url: '/jul17peab',
      views: {
        'inner_content' : {
          templateUrl: 'screens/promos/jul17peab/jul17peab.html',
          controller: 'Jul17peabCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Datos: /* @ngInject */(Server) => {
          return Server.user().get().$promise;
        }
      }
    });
}

export default ProductosConfig;

