let ayudaModule = angular.module('app.ayuda', []);

import AyudaConfig from './ayuda.config';
ayudaModule.config(AyudaConfig);

import AyudaCtrl from './ayuda.controller';
ayudaModule.controller('AyudaCtrl', AyudaCtrl);

import FAQCtrl from './faq/faq.controller';
ayudaModule.controller('FAQCtrl', FAQCtrl);

import RegistroProductoCtrl from './registro-producto/registro-producto.controller';
ayudaModule.controller('RegistroProductoCtrl', RegistroProductoCtrl);

export default ayudaModule;