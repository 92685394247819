/* @ngInject */
class RegistroProductoCtrl {
  constructor($scope, $state, Productos, Server) {

    this._$state    = $state;
    this._$scope    = $scope;
    this._Server    = Server;

    this.marcas     = Productos;
    this.familias = [];
    this.productos = [];
    this.monturas = [];

    this.account  = {};
    this.account.marca      = null;
    this.account.familia    = null;
    this.account.producto   = null;
    this.account.no_aparece = false;

    this.originalAccount = angular.copy(this.account);
  }

  getMonturas() {
    if ( this.account.producto.family_cod === 'as'
      || this.account.producto.family_cod === 'aw'
      || this.account.producto.family_cod === 'ay') {
      return [
        {
          id		: 'C',
          name 	: 'Canon'
        },{
          id		: 'E',
          name 	: 'Sony'
        },{
          id		: 'P',
          name 	: 'PL'
        }
      ];
    }else{
      return [
        {
          id		: '0',
          name 	: 'Canon'
        },{
          id		: '1',
          name 	: 'Nikon'
        },{
          id		: '2',
          name 	: 'Pentax'
        },{
          id		: '3',
          name 	: 'Sony A'
        },{
          id		: '4',
          name 	: 'Sigma'
        },{
          id	:	'6',
          name	:	'Micro 4/3'
        },{
          id	:	'7',
          name	:	'Sony E'
        }
      ];
    }
  }

  changeMarca() {
    this.familias = this.account.marca.families;
    this.productos = [];
  }

  changeTipo() {
    if (this.account.familia){
      this.productos = this.account.familia.products;
    }else {
      this.productos = [];
    }
  }

  sendTicket() {
    this.errorMsg = '';
    this.ticketMsg = '';
    if ( this.ticketForm.$valid ){
      this.account.modelCode = null;
      if ( this.account.producto !== null ){

        this.account.modelCode = this.account.producto.model;
        if (this.account.montura) {
          this.account.modelCode = this.account.modelCode + "" + this.account.montura.id;
        }
        else if (this.account.producto.montura !== '') {
          this.account.modelCode = this.account.modelCode + "" + this.account.producto.montura;
        }
        // EXCEPCIONES
        if ( this.account.modelCode === 'SIAT921' ) {
          this.account.modelCode = 'SIAT911';
        }
        if ( this.account.modelCode === 'SIAT961' ) {
          this.account.modelCode = 'SIAT951';
        }
      }
      this._Server.help().sinRegistroSendProductoForm(this.account).$promise.then( (response) => {
        if ( response.erro ){
          this.ticketError = 'Ha ocurrido un error. Vuelve a intentarlo en unos minutos o contacta con nosotros.'
        }else{
          this.account  = angular.copy(this.originalAccount);

          this.ticketForm.$setPristine();
          this.ticketForm.$setValidity();
          this.ticketForm.$setUntouched();

          this.ticketMsg = 'Formulario enviado. Atenderemos la incidencia en el menor tiempo posible. Disculpe las molestias.'
        }
      })
    }
  }
}

export default RegistroProductoCtrl;
