/* @ngInject */
class AyudaCtrl {
  constructor($scope, $state, PAGETITLE) {

    this._$state = $state;
    this._$scope = $scope;

    this.pageTitle = [PAGETITLE];
  }
}

export default AyudaCtrl;