'use strict';

var $ = require('jquery');

function toggleMenu(){
  return {
    restrict: 'A',
    link: function(scope, element){
      var $body = $('body');

      var toggleMenu = function(){
        if (!$body.hasClass("menu-on-top")){
          $('html').toggleClass("hidden-menu-mobile-lock");
          $body.toggleClass("hidden-menu");
          $body.removeClass("minified");
        } else if ( $body.hasClass("menu-on-top") && $body.hasClass("mobile-view-activated") ) {
          $('html').toggleClass("hidden-menu-mobile-lock");
          $body.toggleClass("hidden-menu");
          $body.removeClass("minified");
        }
      };

      element.on('click', toggleMenu);

      scope.$on('requestToggleMenu', function(){
        toggleMenu();
      });
    }
  }
}

export default toggleMenu;