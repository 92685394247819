'use strict';

function smartDestroySummernote() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-destroy-summernote data-smart-destroy-summernote')
      tElement.on('click', function() {
        angular.element(tAttributes.smartDestroySummernote).destroy();
      })
    }
  }
}

export default smartDestroySummernote;