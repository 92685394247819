'use strict';

var $ = require('jquery');

function dismisser() {
  return {
    restrict: 'A',
    compile: function (element) {
      element.removeAttr('dismisser data-dissmiser')
      var closer = '<button class="close">&times;</button>';
      element.prepend(closer);
      element.on('click', '>button.close', function(){
        element.fadeOut('fast',function(){ $(this).remove(); });

      })
    }
  }
}

export default dismisser;