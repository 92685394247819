/* @ngInject */
export default class Activities{

  constructor($http, $log, AppConstants) {
    function getActivities(callback){

      $http.get(AppConstants.apiRootUrl + '/activities/activity.json').then(function(data){

        callback(data);

      },function(){

        $log.log('Error');
        callback([]);

      });

    }

    function getActivitiesByType(type, callback){

      $http.get(AppConstants.apiRootUrl + '/activities/activity-' + type + '.json').then(function(data){

        callback(data);

      },function(){

        $log.log('Error');
        callback([]);

      });

    }

    return{
      get:function(callback){
        getActivities(callback);
      },
      getbytype:function(type,callback){
        getActivitiesByType(type, callback);
      }
    }
  }
}
