'use strict';

function smartPopover() {
  return {
    restrict: "A",
    link: function(scope, element, attributes){
      var options = {};
      options.content = attributes.smartPopoverHtml;
      options.placement = attributes.popoverPlacement || 'top';
      options.html = true;
      options.trigger =  attributes.popoverTrigger || 'click';
      options.title =  attributes.popoverTitle || attributes.title;
      element.popover(options)

    }

  };
}

export default smartPopover;