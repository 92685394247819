'use strict';

function smartSummernoteEditor(lazyScript) {
  'ngInject';


  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-summernote-editor data-smart-summernote-editor');

      var options = {
        focus : true,
        tabsize : 2
      };

      if(tAttributes.height){
        options.height = tAttributes.height;
      }

      lazyScript.register('build/vendor.ui.js').then(function(){
        tElement.summernote(options);
      });
    }
  }
}

export default smartSummernoteEditor;