'use strict';

function smartIonslider(lazyScript) {
  'ngInject';

  return {
    restrict: 'A',
    compile: function (element, attributes) {
      element.removeAttr('smart-ionslider data-smart-ionslider');

      lazyScript.register('build/vendor.ui.js').then(function(){
        element.ionRangeSlider();
      });
    }
  }
}

export default smartIonslider;