'use strict';

function smartOrderForm(formsCommon, lazyScript) {
  'ngInject';


  return {
    restrict: 'E',
    link: function (scope, form) {
      lazyScript.register('build/vendor.ui.js').then(function(){
        form.validate(angular.extend({
          // Rules for form validation
          rules : {
            name : {
              required : true
            },
            email : {
              required : true,
              email : true
            },
            phone : {
              required : true
            },
            interested : {
              required : true
            },
            budget : {
              required : true
            }
          },

          // Messages for form validation
          messages : {
            name : {
              required : 'Please enter your name'
            },
            email : {
              required : 'Please enter your email address',
              email : 'Please enter a VALID email address'
            },
            phone : {
              required : 'Please enter your phone number'
            },
            interested : {
              required : 'Please select interested service'
            },
            budget : {
              required : 'Please select your budget'
            }
          },

        }, formsCommon.validateOptions));
      });

    }
  }
}

export default smartOrderForm;