'use strict';

function smartTimepicker() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-timepicker data-smart-timepicker');
      tElement.timepicker();
    }
  }
}

export default smartTimepicker;