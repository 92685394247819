/* @ngInject */
function DashboardConfig($stateProvider) {
  $stateProvider
    .state('app.dashboard', {
      url: '/',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/dashboard/dashboard.html',
          controller: 'DashboardCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Brands: /* @ngInject */(Server) => {
          return Server.brands().get().$promise;
        },
        Productos: /* @ngInject */(Server) => {
          return Server.products().get().$promise;
        }
      }
    });
}

export default DashboardConfig;

