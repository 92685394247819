'use strict';

function smartCkEditor() {
  return {
    restrict: 'A',
    compile: function ( tElement) {
      tElement.removeAttr('smart-ck-editor data-smart-ck-editor');
      //CKEDITOR.basePath = 'bower_components/ckeditor/';

      CKEDITOR.replace( tElement.attr('name'), { height: '380px', startupFocus : true} );
    }
  }
}

export default smartCkEditor;