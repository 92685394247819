/* @ngInject */
function authInterceptor(JWT, AppConstants, $q, $injector) {
  var inFlightAuthRequest = null;

  return {
    // automatically attach Authorization header
    request: function(config) {
      var deferred = $q.defer();

      if(config.url.indexOf(AppConstants.api) === 0 && JWT.get()) {
        config.headers.Authorization = 'Bearer ' + JWT.get();
      }
      if( !JWT.userHasConnection() ){
        deferred.reject(config);
      }else{
        deferred.resolve(config);
      }
      return deferred.promise;
    },

    // Handle 401
    responseError: function(rejection) {
      switch (rejection.status){
        case 401:
          var deferred = $q.defer();
          if(!inFlightAuthRequest){
            inFlightAuthRequest = $injector.get('Auth').petitions().refresh().$promise;
          }
          inFlightAuthRequest.then((response) => {
            inFlightAuthRequest = null;
            if(response.token){
              JWT.save(response.token);
              $injector.get('$http')(rejection.config).then((res) => {
                deferred.resolve(res);
              },(err) => {
                deferred.reject();
              })
            }else{
              deferred.reject();
            }
          }, (error) => {
            inFlightAuthRequest = null;
            deferred.reject();
            JWT.destroy();
            $injector.get('$state').go(AppConstants.initialState,{},{reload: true});
            return;
          });
          return deferred.promise;
          break;
        default:
          if ( JWT.userHasConnection() ){
            JWT.destroy();
            $injector.get('$state').go(AppConstants.initialState,{},{reload: true});
          }else{
            var deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
          }
          break;
      }
      return rejection || $q.when(rejection);
    }
  }
}

export default authInterceptor;