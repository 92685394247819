/* @ngInject */
export default class Auth {
  constructor(JWT, $window, AppConstants, $state, $resource) {

    this._JWT = JWT;
    this._AppConstants = AppConstants;
    this._$state = $state;
    this._$resource = $resource;

    this.current = null;
    this.push_token = null;
    this.localStorage = $window.localStorage;
  }

  logout() {
    this.current = null;
    this._JWT.destroy();
    this._$state.go(this._$state.$current, null, {reload: true});
  }

  authed() {
    return this._JWT.userHasToken();
  }

  setPushToken(push_token) {
    this.localStorage['push_token'] = this.push_token = push_token;
  }

  getPushToken() {
    return this.localStorage['push_token'];
  }

  petitions() {
    let url = `${this._AppConstants.api}/auth/`;
    let opts = {
      refresh: {
        url: `${url}refresh`,
        method: 'GET',
        isArray: false ,
        skipAuthorization: true,
        ignoreLoadingBar: true
      },
      initialise: {
        url: `${url}initialise`,
        method: 'POST',
        isArray: false ,
        skipAuthorization: true,
        ignoreLoadingBar: true
      }
    };

    return this._$resource(url,{},opts);
  }
}
