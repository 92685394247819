/* @ngInject */
class PerfilCtrl {
  constructor($q, $scope, $state, $timeout, $filter, PAGETITLE, JWT, Datos, Server) {

    this._$state = $state;
    this._JWT = JWT;
		this._Server = Server;
    this._$filter = $filter;
    this._$timeout = $timeout;

    this.pageTitle = [PAGETITLE];

    if (!this._JWT.userHasToken()) {
    	this._$state.go('login');
    }

    this.profile = Datos;

    this.showRegister = false;
    this.showWarranty = false;

    // Image options
    if (!this.profile.avatar)
      this.myImage = '/images/profile-placeholder.jpg';
    else
      this.myImage = this.profile.avatar;

    this.myCroppedImage = '';

    var handleFileSelect = (evt) => {
      var file=evt.currentTarget.files[0];
      var reader = new FileReader();
      reader.onload = (evt) => {
        this.myImage=evt.target.result;
      };
      reader.readAsDataURL(file);
    };

    angular.element(document.querySelector('#fileInput')).on('change',handleFileSelect);

    // Date options
    this.openDatepicker = function() {
      this.popupDatepicker.opened = true;
    };
    this.popupDatepicker = {
      opened: false
    };
    this.dateOptions = {
      initDate: moment('01/01/1980').toDate()
    };
    this.format = 'dd/MM/yyyy';

    this.profile.birthdate = moment(this.profile.birthdate).toDate();
  }

  selectImage() {
    document.querySelector('#fileInput').click();
  }

  upload() {
    this.uploadMsg = '';
    if(this.updateForm.$valid) {
      this.profile.avatar = this.myCroppedImage;
      this._Server.user().update(this.profile).$promise.then((response) => {
        if(response.type && response.type == 'error'){
          this.errorUploadMsg = response.msg;

        } else if(response.type && response.type == 'success'){
          this.uploadMsg = 'Imagen actualizada correctamente!';

        }else{
          this.errorUploadMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        }
      },(error) => {
        this.errorUploadMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
    }
  }

  updateEmail() {
    this.errorEmailMsg = '';
    this.updateEmailMsg = '';
    if(this.updateEmailForm.$valid) {
      var data = angular.copy(this.profile);
      this._Server.user().updateEmail(data).$promise.then((response) => {
        console.log(response);
        if(response.type && response.type == 'error'){
          this.errorEmailMsg = response.msg;

        } else if(response.type && response.type == 'success'){
          this.updateEmailMsg = 'Email actualizado correctamente!';

        }else{
          this.errorEmailMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        }
      },(error) => {
        this.errorEmailMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
    } else {
      this.updateEmailForm.email.$dirty = true;
    }
  }

  update() {
    this.errorMsg = '';
    this.updateMsg = '';
    if(this.updateForm.$valid) {
      var data = angular.copy(this.profile);
      data.birthdate = moment(this.profile.birthdate).format('YYYY-MM-DD');
      this._Server.user().update(data).$promise.then((response) => {
        console.log(response);
        if(response.type && response.type == 'error'){
          this.errorMsg = response.msg;

        } else if(response.type && response.type == 'success'){
          this.updateMsg = 'Perfil actualizado correctamente!';

        }else{
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        }
      },(error) => {
        this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
    } else {
      this.updateForm.first_name.$dirty = true;
      this.updateForm.last_name.$dirty = true;
    }
  }

  cancelAccount() {
    let response = confirm("¿Seguro que quiere cancelar su cuenta? Todos sus datos serán borrados y no podrá volver a acceder.");
    if (response == true) {
      this._Server.cancelAccount().get().$promise.then((response) => {
          if(response.type && response.type == 'error'){
            this.errorMsg = response.msg;

          } else if(response.type && response.type == 'success'){
            //this.updateMsg = response.msg;
            this._$state.go('login', { msg: response.msg });

          }else{
            this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
          }
        },(error) => {
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        })
    }
  }
}

export default PerfilCtrl;