'use strict';

var $ = require('jquery');

function smartDatepicker() {
  return {
    restrict: 'A',
    scope: {
      options: '='
    },
    link: function (scope, element, attributes) {

      var onSelectCallbacks = [];
      if (attributes.minRestrict) {
        onSelectCallbacks.push(function (selectedDate) {
          $(attributes.minRestrict).datepicker('option', 'minDate', selectedDate);
        });
      }
      if (attributes.maxRestrict) {
        onSelectCallbacks.push(function (selectedDate) {
          $(attributes.maxRestrict).datepicker('option', 'maxDate', selectedDate);
        });
      }

      //Let others know about changes to the data field
      onSelectCallbacks.push(function (selectedDate) {
        //CVB - 07/14/2015 - Update the scope with the selected value
        element.triggerHandler("change");

        //CVB - 07/17/2015 - Update Bootstrap Validator
        var form = element.closest('form');

        if(typeof form.bootstrapValidator == 'function')
          form.bootstrapValidator('revalidateField', element.attr('name'));
      });

      var options = _.extend({
        prevText: '<i class="fa fa-chevron-left"></i>',
        nextText: '<i class="fa fa-chevron-right"></i>',
        onSelect: function (selectedDate) {
          angular.forEach(onSelectCallbacks, function (callback) {
            callback.call(this, selectedDate)
          })
        }
      }, scope.options || {});


      if (attributes.numberOfMonths) options.numberOfMonths = parseInt(attributes.numberOfMonths);

      if (attributes.dateFormat) options.dateFormat = attributes.dateFormat;

      if (attributes.defaultDate) options.defaultDate = attributes.defaultDate;

      if (attributes.changeMonth) options.changeMonth = attributes.changeMonth == "true";


      element.datepicker(options)
    }
  }
}

export default smartDatepicker;