/**
 * Clase de entrada. En ocasiones puede cargarse antes que acaban de ejecutarse las tareas en el Run.
 * Aquí comprobaremos si hemos sincronizado la primera vez, si tenemos que bloquear por actualización...
 */
/* @ngInject */
class HeaderCtrl {
  constructor($scope, $state, Auth, $cookies, $translate, $rootScope) {

    this._$state = $state;
    this._Auth = Auth;

    let lang = $cookies.get('language');
    if ( typeof lang !== 'undefined' ){
      $translate.use(lang);
    }else{
      $translate.use('es');
      $cookies.put('language','es');
    }

    $rootScope.getMonturas = function(family_cod) {
      if ( family_cod === 'as'
        || family_cod === 'aw'
        || family_cod === 'ay') {
        return [
          {
            id		: 'C',
            name 	: 'Canon'
          },{
            id		: 'E',
            name 	: 'Sony'
          },{
            id		: 'P',
            name 	: 'PL'
          }
        ];
      }else{
        return [
          {
            id		: '0',
            name 	: 'Canon'
          },{
            id		: '1',
            name 	: 'Nikon'
          },{
            id		: '2',
            name 	: 'Pentax'
          },{
            id		: '3',
            name 	: 'Sony A'
          },{
            id		: '4',
            name 	: 'Sigma'
          },{
            id	:	'6',
            name	:	'Micro 4/3'
          },{
            id	:	'7',
            name	:	'Sony E'
          }
        ];
      }
    }
  }

  logout() {
  	this._Auth.logout();

  }
}

export default HeaderCtrl;
