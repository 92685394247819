'use strict';

function bootstrapAttributeForm(){

  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'app/SmartAdmin/directives/forms/bootstrap-validation/bootstrapAttributeForm.html',
    link: function(scope, form){
      form.bootstrapValidator();


    }

  }
}

export default bootstrapAttributeForm;