'user strict';

function smartKnob() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-knob data-smart-knob');

      tElement.knob();
    }
  }
}

export default smartKnob;
