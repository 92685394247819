'use strict';

function smartTagsinput() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-tagsinput data-smart-tagsinput');
      tElement.tagsinput();
    }
  }
}

export default smartTagsinput;