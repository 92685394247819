let dashboardModule = angular.module('app.dashboard', []);

import DashboardConfig from './dashboard.config';
dashboardModule.config(DashboardConfig);

import DashboardCtrl from './dashboard.controller';
dashboardModule.controller('DashboardCtrl', DashboardCtrl);

import ActivitiesCtrl from './activities/activities.controller';
dashboardModule.controller('ActivitiesCtrl', ActivitiesCtrl);

// Directives
import activitiesDropdownToggle from './activities/activities-dropdown-toggle.directive';
dashboardModule.directive('activitiesDropdownToggle', activitiesDropdownToggle);

export default dashboardModule;