/* @ngInject */
function AyudaConfig($stateProvider) {
  $stateProvider
    .state('app.ayuda', {
      url: '/ayuda',
      abstract: true,
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/ayuda/layout.html'
        }
      }
    })
    .state('app.ayuda.faq', {
      url: '/faq',
      views: {
        'inner_content' : {
          templateUrl: 'screens/ayuda/faq/faq.html',
          controller: 'FAQCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.AYUDA.PAGE_TITLE');
        }
      }
    })
    .state('app.ayuda.registro-producto', {
      url: '/registro-producto',
      views: {
        'inner_content' : {
          templateUrl: 'screens/ayuda/registro-producto/registro-producto.html',
          controller: 'RegistroProductoCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.AYUDA.PAGE_TITLE');
        },
        Productos: /* @ngInject */(Server) => {
          return Server.products().get().$promise;
        }
      }
    });
}

export default AyudaConfig;