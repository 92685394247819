/* @ngInject */
function PerfilConfig($stateProvider) {
  $stateProvider
    .state('app.perfil', {
      url: '/perfil',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/perfil/perfil.html',
          controller: 'PerfilCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Datos: /* @ngInject */(Server) => {
          return Server.user().get().$promise;
        },
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.PERFIL.PAGE_TITLE');
        }
      }
    });
}

export default PerfilConfig;

