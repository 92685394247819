/* @ngInject */
class ForgotPasswordCtrl {
  constructor($http, $location, AppConstants, JWT, $state) {

    this._$http = $http;
    this._AppConstants = AppConstants;
    this._JWT = JWT;
    this._$state = $state;

    this.account = {};

    this.errorMsg = '';
  }

  recoverPassword() {
    this.errorMsg = '';
    let url = `${this._AppConstants.api}/reset-password`;

    if(this.loginForm.$valid) {
      this._$http({
        method: 'POST',
        url: url,
        data: {
          email: this.account.email,
        },
        skipAuthorization: true
      })
        .then((response) => {
          if(response.data.type === 'success'){
            this.validateMsg = 'Compuebe su dirección de correo donde le enviaremos un email con instrucciones para resetear su contraseña.';
          } else if(response.data.type === 'error'){
            this.errorMsg = response.data.msg;
          }else{
            this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
          }
        },() => {
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        })
    }else{
      this.loginForm.email.$dirty = true;
    }
  }
}

export default ForgotPasswordCtrl;