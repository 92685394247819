/* @ngInject */
function LoginConfig($stateProvider) {
  $stateProvider
    .state('realLogin', {
      url: '/real-login',
      views: {
        'root': {
          templateUrl: 'auth/real-login/realLogin.html',
          controller: 'RealLoginCtrl',
          controllerAs: '$ctrl'
        }
      },
      data: {
        title: 'Login',
        rootId: 'extra-page'
      }
    })
    .state('login', {
      url: '/login',
      views: {
        'root': {
          templateUrl: 'auth/login/login.html',
          controller: 'LoginCtrl',
          controllerAs: '$ctrl'
        }
      },
      params: {
        msg: null
      },
      data: {
        title: 'Login',
        htmlId: 'extr-page'
      }
    })
    .state('forgot-password', {
      url: '/forgot-password',
      views: {
        'root': {
          templateUrl: 'auth/password/forgot-password.html',
          controller: 'ForgotPasswordCtrl',
          controllerAs: '$ctrl'
        }
      },
      data: {
        title: 'Recuperar contraseña',
        htmlId: 'extr-page'
      }
    })
    .state('reset-password', {
      url: '/reset-password',
      views: {
        'root': {
          templateUrl: 'auth/password/reset-password.html',
          controller: 'ResetPasswordCtrl',
          controllerAs: '$ctrl'
        }
      },
      data: {
        title: 'Cambiar contraseña',
        htmlId: 'extr-page'
      }
    })
    .state('register', {
      url: '/register',
      views: {
        'root': {
          templateUrl: 'auth/register/register.html',
          controller: 'RegisterCtrl',
          controllerAs: '$ctrl'
        }
      },
      data: {
        title: 'Registro',
        htmlId: 'extr-page'
      },
      resolve: {
        Brands: /* @ngInject */(Server) => {
          return Server.brands().get().$promise;
        }
      },
    })
    .state('register-help', {
      url: '/register-help',
      views: {
        'root': {
          templateUrl: 'auth/register-help/register-help.html',
          controller: 'RegisterHelpCtrl',
          controllerAs: '$ctrl'
        }
      },
      data: {
        title: 'Ayuda registro',
        htmlId: 'extr-page'
      },
      resolve: {
        Productos: /* @ngInject */(Server) => {
          return Server.products().get().$promise;
        }
      },
    })
    .state('auth', {
      url: '/auth',
      abstract: true,
      views: {
        'root': {
          templateUrl: 'auth/layout/two-columns.html'
        }
      }
    })
      .state('auth.jul17peab', {
        url: '/jul17peab',
        views: {
          'left_column': {
            templateUrl: 'auth/jul17peab/jul17peab.left.html'
          },
          'right_column': {
            templateUrl: 'auth/jul17peab/jul17peab.right.html',
            controller: 'RegisterCtrl',
            controllerAs: '$ctrl'
          }
        },
        data: {
          title: 'Register',
          htmlId: 'extr-page'
        },
        resolve: {
          Productos: /* @ngInject */(Server) => {
            return Server.products().get().$promise;
          }
        }
      })
      .state('auth.register-international', {
        url: '/register-international',
        views: {
          'left_column': {
            templateUrl: 'auth/register-international/register.left.html'
          },
          'right_column': {
            templateUrl: 'auth/register-international/register.right.html',
            controller: 'RegisterCtrl',
            controllerAs: '$ctrl'
          }
        },
        data: {
          title: 'Register International',
          htmlId: 'extr-page'
        },
        resolve: {
          Productos: /* @ngInject */(Server) => {
            return Server.internationalWarranties().get().$promise;
          }
        }
      });
  }

export default LoginConfig;

