/* @ngInject */
function DownloadsConfig($stateProvider) {
  $stateProvider
    .state('app.downloads', {
      url: '/downloads',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/downloads/downloads.html',
          controller: 'DownloadsCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Descargas: /* @ngInject */(Server) => {
          return Server.downloads().get().$promise;
        },
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.DOWNLOADS.PAGE_TITLE');
        }
      }
    });
}

export default DownloadsConfig;

