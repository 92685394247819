'use strict';

function smartClockpicker() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-clockpicker data-smart-clockpicker');

      var options = {
        placement: 'top',
        donetext: 'Done'
      };

      tElement.clockpicker(options);
    }
  }
}

export default smartClockpicker;