/* @ngInject */
function HomeConfig($stateProvider) {
  $stateProvider
    .state('app.home', {
      url: '/home',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/home/home.html',
          controller: 'HomeCtrl',
          controllerAs: '$ctrl'
        }
      }
    });
}

export default HomeConfig;

