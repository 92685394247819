'use strict';

function smartFueluxWizard() {
  return {
    restrict: 'A',
    scope: {
      smartWizardCallback: '&'
    },
    link: function (scope, element, attributes) {

      var wizard = element.wizard();

      var $form = element.find('form');

      wizard.on('actionclicked.fu.wizard', function(e, data){
        if ($form.data('validator')) {
          if (!$form.valid()) {
            $form.data('validator').focusInvalid();
            e.preventDefault();
          }
        }
      });

      wizard.on('finished.fu.wizard', function (e, data) {
        var formData = {};
        _.each($form.serializeArray(), function(field){
          formData[field.name] = field.value
        });
        if(typeof scope.smartWizardCallback() === 'function'){
          scope.smartWizardCallback()(formData)
        }
      });
    }
  }
}

export default smartFueluxWizard;