/* @ngInject */
class RegisterCtrl {
  constructor($http, $state, $window, JWT, AppConstants, $cookies, Server, Brands) {

    this._$http = $http;
    this._$state = $state;
    this._$window = $window;
    this._JWT = JWT;
    this._$cookies = $cookies;
    this._AppConstants = AppConstants;
    this._Server = Server;

    // Flags
    this.doingLogin = false;

    this.marcas = Brands.data;
    this.products = [];
    this.noProductsFound = false;
    this.searchingProducts = false;

    this.account = {};

    this.errorMsg = '';
    this.registerMsg = '';

    // Date options
    this.openDatepicker = function () {
      this.popupDatepicker.opened = true;
    };
    this.popupDatepicker = {
      opened: false
    };
    this.dateOptions = {};
    this.format = 'dd/MM/yyyy';
  }

  onSearchProducts() {
    this.noProductsFound = false;
    this.searchingProducts = true;
    this.products = [];
    this.account.selectedProduct = null;
    this._Server.dailySales().get({serial: this.account.serial, brand: this.account.marca.id}).$promise
      .then((res) => {
        this.searchingProducts = false;
        if (res.data.length === 0) {
          // error
          this.noProductsFound = true;
        } else {
          this.products = res.data;
        }
      });
  }

  register() {
    this.errorMsg = '';
    this.registerMsg = '';
    let url = `${this._AppConstants.api}/register`;

    let lang = this._$cookies.get('language');
    if (typeof lang === 'undefined') {
      lang = 'es';
    }

    if (this.registerForm.$valid) {

      if (this.account.passStrength < 83) {
        this.errorMsg = 'La contraseña no es suficientemente fuerte.';
        return;
      }

      if (this.account.password !== this.account.passwordConfirm) {
        this.errorMsg = 'La confirmación de la contraseña no coincide.';
        return;
      }

      if (!this.account.agree) {
        this.errorMsg = 'Tiene que aceptar los Términos y Condiciones.';
        return;
      }

      this.doingLogin = true;

      let modelCode = this.account.selectedProduct.model;
      // EXCEPCIONES
      if (modelCode === 'SIAT921') {
        modelCode = 'SIAT911';
      }
      if (modelCode === 'SIAT961') {
        modelCode = 'SIAT951';
      }

      this._$http({
        method: 'POST',
        url: url,
        data: {
          email: this.account.email,
          password: this.account.password,
          categoria_id: 1,
          lang: lang,
          serial_number: this.account.serial,
          brand: this.account.marca.id,
          family: this.account.selectedProduct.family_cod,
          product: modelCode,
          first_name: this.account.firstname,
          last_name: this.account.lastname,
          comprado: moment(this.account.comprado).format('YYYY-MM-DD')
        },
        skipAuthorization: true
      })
        .then((response) => {
          this.doingLogin = false;

          if (response.data.type && response.data.type === 'error') {
            this.errorMsg = response.data.msg;

          } else if (response.data.token) {
            //this._JWT.save(response.data.token);
            this.registerMsg = 'Usuario registrado correctamente! Para acceder a su panel debe validar su cuenta de correo electrónico a través del e-mail que le hemos enviado.';
            //this._$state.go('app.dashboard');


          } else {
            this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
          }
        }, () => {
          this.doingLogin = true;
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        });
    } else {
      this._$window.scrollTo(0, 0);

      this.registerForm.email.$dirty = true;
      this.registerForm.password.$dirty = this.account.passStrength < 83;
      this.registerForm.passwordConfirm.$dirty = this.account.password !== this.account.passwordConfirm;
      this.registerForm.firstname.$dirty = true;
      this.registerForm.lastname.$dirty = true;
      this.registerForm.marca.$dirty = true;
      this.registerForm.comprado.$dirty = true;
      this.registerForm.serial.$dirty = true;
      if (this.registerForm.serialExtra) this.registerForm.serialExtra.$dirty = true;
    }
  }

}

export default RegisterCtrl;
