let servicesModule = angular.module('app.services', []);

import Server from './server.service';
servicesModule.service('Server',Server);

import Promos from './promos.service';
servicesModule.service('Promos',Promos);

import JWT from './jwt.service';
servicesModule.service('JWT', JWT);

import Auth from './auth.service';
servicesModule.service('Auth', Auth);

import Activities from './activities.service';
servicesModule.service('Activities', Activities);

import Swal from './swal.service';
servicesModule.service('Swal', Swal);

export default servicesModule;
