let module = angular.module('ngPasswordStrength', []);

import ngPasswordStrength from './directive/ng-password-strength.directive';
module.directive('ngPasswordStrength', ngPasswordStrength);

import PasswordStrengthEntropyService from './services/password-strength-entropy.factory';
module.factory('PasswordStrengthEntropyService', PasswordStrengthEntropyService);

import PasswordStrengthFormulaService from './services/password-strength-formula.factory';
module.factory('PasswordStrengthFormulaService', PasswordStrengthFormulaService);

export default module;