'use strict';

function SmartTooltip() {
  return {
    restrict: 'A',
    link: function(scope, element, attributes){
      element.tooltip({
        placement: attributes.tooltipPlacement || 'top',
        html: true,
        title: attributes.smartTooltipHtml
      })
    }
  };
}

export default SmartTooltip;