'use strict';

var $ = require('jquery');

function smartUislider($parse, lazyScript) {
  'ngInject';


  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {

      tElement.removeAttr('smart-uislider data-smart-uislider');

      lazyScript.register('build/vendor.ui.js').then(function(){
        tElement.bootstrapSlider();

        $(tElement.data('bootstrapSlider').sliderElem).prepend(tElement);
      })

    }
  }
}

export default smartUislider;