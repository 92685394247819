/* @ngInject */
export default class Server {

  constructor( AppConstants, $state, $resource ) {

    this._AppConstants = AppConstants;
    this._$state = $state;
    this._$resource = $resource;

    this.current = null;
  }

  products() {
    let url = `${this._AppConstants.api}/products`;
    let opts = {
      get: {
        method: 'GET',
        isArray: true
      },
      getInternationalWarranties:{
        url: `${this._AppConstants.api}/international-warranties`,
        method: 'GET',
        isArray: true
      }
    };

    return this._$resource(url,{},opts);
  }

  brands() {
    let url = `${this._AppConstants.api}/brands`;
    let opts = {};

    return this._$resource(url,{},opts);
  }

  internationalWarranties() {
    let url = `${this._AppConstants.api}/international-warranties`;
    let opts = {
      get: {
        method: 'GET',
        isArray: true
      }
    };

    return this._$resource(url,{},opts);
  }

  sales() {
    let url = `${this._AppConstants.api}/me/sale`;
    let opts = {
      register: {
        method: 'PUT',
        isArray: false
      },
      getWarrantyPDF:{
        url: `${this._AppConstants.api}/sales/:sale_id/warranty-pdf`,
        method: 'GET',
        params: {
          sale_id : '@sale_id'
        }
      }
    };

    return this._$resource(url,{},opts);
  }

  dailySales() {
    let url = `${this._AppConstants.api}/daily-sales`;
    let opts = {};

    return this._$resource(url,{},opts);
  }

  warranties() {
    let url = `${this._AppConstants.api}/me/warranty`;
    let opts = {
      register: {
        method: 'PUT',
        isArray: false
      }
    };

    return this._$resource(url,{},opts);
  }

  user() {
    let url = `${this._AppConstants.api}/me`;
    let opts = {
      get: {
        method: 'GET',
        isArray: false
      },
      getConfig: {
        url: `${url}/config`,
        method: 'GET',
        isArray: true
      },
      update: {
        method: 'POST',
        isArray: false
      },
      updateEmail: {
        url: `${url}/email`,
        method: 'POST',
        isArray: false
      },
      updateConfig: {
        url: `${url}/config`,
        method: 'POST',
        isArray: false
      }
    };

    return this._$resource(url,{},opts);
  }

  cancelAccount() {
    let url = `${this._AppConstants.api}/me/cancel-account`;
    let opts = {
    };

    return this._$resource(url,{},opts);
  }

  downloads() {
    let url = `${this._AppConstants.api}/me/downloads`;
    let opts = {
      get: {
        method: 'GET',
        isArray: true
      }
    };

    return this._$resource(url,{},opts);
  }

  feed() {
    let url = `${this._AppConstants.api}/feed`;

    let opts = {
      getSigma: {
        url: `${url}/sigma`,
        method: 'GET',
        isArray: true
      },
      getReflecta: {
        url: `${url}/reflecta`,
        method: 'GET',
        isArray: true
      }
    };

    return this._$resource(url,{},opts);
  }

  help() {
    let url = `${this._AppConstants.api}/help`;

    let opts = {
      sendProductoForm: {
        url: `${url}/registro-producto`,
        method: 'POST'
      },
      sinRegistroSendProductoForm: {
        url: `${url}/no-registro-producto`,
        method: 'POST',
        skipAuthorization: true
      }
    };

    return this._$resource(url,{},opts);
  }

  bigThree() {
    let url = `${this._AppConstants.api}/big-three`;

    let opts = {
      affectedProducts: {
        url: `${url}/affected-products`,
        method: 'GET'
      },
      createPromoCode: {
        url: `${url}/qrcode`,
        method: 'POST'
      }
    };

    return this._$resource(url,{},opts);
  }

}
