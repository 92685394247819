/* @ngInject */
class SettingsCtrl {
  constructor( $state, PAGETITLE, $filter, JWT, Server) {

    this._$state = $state;
    this._JWT = JWT;
		this._Server = Server;
    this._$filter = $filter;

    this.pageTitle = [PAGETITLE];

    if (!this._JWT.userHasToken()) {
    	this._$state.go('login');
    }

    this.profile = {};
    this.profile.newsletters = false;
    this.profile.notifications = false;
  }

  update() {
    this.errorMsg = '';
    this.updateMsg = '';

    const data = angular.copy(this.profile);
    this._Server.user().updateConfig(data).$promise.then((response) => {
      console.log(response);
      if(response.type && response.type === 'error'){
        this.errorMsg = response.msg;

      } else if(response.type && response.type === 'success'){
        this.updateMsg = 'Configuración actualizada correctamente!';

      }else{
        this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      }
    },(error) => {
      this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
    })
   
  }

}

export default SettingsCtrl;