/* @ngInject */
class ProductosCtrl {
  constructor($log, $scope, $rootScope, $cookies, $window, $state, $filter, JWT, Datos, Promos) {

    this._$state = $state;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._$state = $state;
    this._$log = $log;
    this._$cookies = $cookies;
    this._JWT = JWT;
		this._Promos = Promos;
    this._$filter = $filter;
    this._$window = $window;

    if (!this._JWT.userHasToken()) {
    	this._$state.go('login');
    }

    //Flags
    this.loadingDownload = false;

    this.data = Datos;
    this.blockPromo = false;
    this.errorEnvio = false;
    this.sendingDoc = false;

    this.docs = null;
    this.finalDocs = [];

    if ( this.data.nif === null ){
      this.blockPromo = true;
    }

    $scope.$watch( () => this.docs, () => {
      if ( this.docs !== null ){
        this.finalDocs = this.finalDocs.concat(this.docs);
      }
    });

  }

  borrarArchivo(doc) {
    let index = this.finalDocs.indexOf(doc);

    this.finalDocs.splice(index, 1);
  }

  enviarDocumentacion() {

    this.sendingDoc = true;

    this._Promos.jul17peab().save(this.finalDocs).$promise.then( (result) => {
      this._$log.debug(result);

      this.sendingDoc = false;
      if ( result.status === true ){
        this._$cookies.put('jul17peab', true);
        this._$rootScope.$broadcast('$jul17peab:sent');
        this._$state.go('app.dashboard');
      }else{
        this.errorEnvio = true;
      }
    });
  }

}

export default ProductosCtrl;