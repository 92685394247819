'use strict';

function smartMaskedInput(lazyScript){
  'ngInject';


  return {
    restrict: 'A',
    compile: function(tElement, tAttributes){
      tElement.removeAttr('smart-masked-input data-smart-masked-input');

      lazyScript.register('build/vendor.ui.js').then(function(){

        var options = {};
        if(tAttributes.maskPlaceholder) options.placeholder =  tAttributes.maskPlaceholder;
        tElement.mask(tAttributes.smartMaskedInput, options);
      })
    }
  }
}

export default smartMaskedInput;