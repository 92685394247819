import authInterceptor from './auth.interceptor';

/* @ngInject */
function AppConfig($stateProvider, $urlRouterProvider, $httpProvider, $compileProvider, $locationProvider) {

  $locationProvider.html5Mode(true);

  // JWT interceptor
  $httpProvider.interceptors.push(authInterceptor);

  // Deshabilitar información de debug para mejorar el rendimiento
  $compileProvider.debugInfoEnabled(false);
  // Rutas generales de las que colgarán el resto
  $stateProvider
    .state('app', {
      abstract: true,
      views: {
        root: {
          templateUrl: 'layout/app.html'
        }
      }
    });

  $urlRouterProvider.otherwise('/');
}

export default AppConfig;
