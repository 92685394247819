'use strict';

var $ = require('jquery');

function searchMobile() {
  return {
    restrict: 'A',
    compile: function (element, attributes) {
      element.removeAttr('search-mobile data-search-mobile');

      element.on('click', function (e) {
        $('body').addClass('search-mobile');
        e.preventDefault();
      });

      $('#cancel-search-js').on('click', function (e) {
        $('body').removeClass('search-mobile');
        e.preventDefault();
      });
    }
  }
}

export default searchMobile;