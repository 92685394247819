let SmartAdminDirectives = angular.module('SmartAdmin.directives', []);

import SmartPopover from './smartPopover.directive';
SmartAdminDirectives.directive('smartPopover',SmartPopover);

import SmartTooltip from './smartTooltip.directive';
SmartAdminDirectives.directive('smartTooltip',SmartTooltip);

import BigBreadcrumbs from './bigBreadcrumbs.directive';
SmartAdminDirectives.directive('bigBreadcrumbs',BigBreadcrumbs);

import Dismisser from './dismisser.directive';
SmartAdminDirectives.directive('dismisser', Dismisser);

import RadioToggle from './radioToggle.directive';
SmartAdminDirectives.directive('radioToggle',RadioToggle);

import SmartDeviceDetect from './smartDeviceDetect.directive';
SmartAdminDirectives.directive('smartDeviceDetect',SmartDeviceDetect);

import SmartInclude from './smartInclude.directive';
SmartAdminDirectives.directive('smartInclude',SmartInclude);

import SmartLayout from './smartLayout.directive';
SmartAdminDirectives.directive('smartLayout', SmartLayout);

import SmartPageTitle from './smartPageTitle.directive';
SmartAdminDirectives.directive('smartPageTitle',SmartPageTitle);

import SmartRouterAnimationWrap from './smartRouterAnimation.directive';
SmartAdminDirectives.directive('smartRouterAnimationWrap',SmartRouterAnimationWrap);

import SpeechRecognition from './smartSpeech.directive';
SmartAdminDirectives.directive('speechRecognition',SpeechRecognition);

import StateBreadcrumbs from './stateBreadcrumbs.directive';
SmartAdminDirectives.directive('stateBreadcrumbs',StateBreadcrumbs);


// WIDGETS
import JarvisWidget from './widgets/jarvisWidget.directive';
SmartAdminDirectives.directive('jarvisWidget',JarvisWidget);

import WidgetGrid from './widgets/widgetGrid.directive';
SmartAdminDirectives.directive('widgetGrid',WidgetGrid);


// NAVIGATION
import SmartMenu from './navigation/smartMenu.directive';
SmartAdminDirectives.directive('smartMenu',SmartMenu);

import SmartMenuItems from './navigation/smartMenuItems.directive';
SmartAdminDirectives.directive('smartMenuItems',SmartMenuItems);


// ACTIONS
import FullScreen from './actions/fullScreen.directive';
SmartAdminDirectives.directive('fullScreen', FullScreen);

import MinifyMenu from './actions/minifyMenu.directive';
SmartAdminDirectives.directive('minifyMenu', MinifyMenu);

import ReloadState from './actions/reloadState.directive';
SmartAdminDirectives.directive('reloadState', ReloadState);

import ResetWidgets from './actions/resetWidgets.directive';
SmartAdminDirectives.directive('resetWidgets', ResetWidgets);

import SearchMobile from './actions/searchMobile.directive';
SmartAdminDirectives.directive('searchMobile', SearchMobile);

import ToggleMenu from './actions/toggleMenu.directive';
SmartAdminDirectives.directive('toggleMenu', ToggleMenu);

/*********************************************************/
// FORMS
/*********************************************************/

// Wizard

import smartWizard from './forms/smartWizard.directive';
SmartAdminDirectives.directive('smartWizard', smartWizard);

import smartFueluxWizard from './forms/smartFueluxWizard.directive';
SmartAdminDirectives.directive('smartFueluxWizard',smartFueluxWizard);

// Validate

import smartValidateForm from './forms/smartValidateForm.directive';
SmartAdminDirectives.directive('smartValidateForm',smartValidateForm);

// Upload

import smartDropzone from './forms/smartDropzone.directive';
SmartAdminDirectives.directive('smartDropzone', smartDropzone);

// Input

import smartClockpicker from './forms/input/smartClockPicker.directive';
SmartAdminDirectives.directive('smartClockpicker',smartClockpicker);

import smartColorpicker from './forms/input/smartColorPicker.directive';
SmartAdminDirectives.directive('smartColorpicker', smartColorpicker);

import smartDatepicker from './forms/input/smartDatePicker.directive';
SmartAdminDirectives.directive('smartDatepicker', smartDatepicker);

import smartDuallistbox from './forms/input/smartDuallistbox.directive';
SmartAdminDirectives.directive('smartDuallistbox', smartDuallistbox);

import smartIonslider from './forms/input/smartIonslider.directive';
SmartAdminDirectives.directive('smartIonslider', smartIonslider);

import smartKnob from './forms/input/smartKnob.directive';
SmartAdminDirectives.directive('smartKnob', smartKnob);

import smartMaskedInput from './forms/input/smartMaskedInput.directive';
SmartAdminDirectives.directive('smartMaskedInput', smartMaskedInput);

import smartNouislider from './forms/input/smartNouislider.directive';
SmartAdminDirectives.directive('smartNouislider', smartNouislider);

import smartSelect2 from './forms/input/smartSelect2.directive';
SmartAdminDirectives.directive('smartSelect2', smartSelect2);

import smartSpinner from './forms/input/smartSpinner.directive';
SmartAdminDirectives.directive('smartSpinner', smartSpinner);

import smartTagsinput from './forms/input/smartTagsinput.directive';
SmartAdminDirectives.directive('smartTagsinput', smartTagsinput);

import smartTimepicker from './forms/input/smartTimepicker.directive';
SmartAdminDirectives.directive('smartTimepicker', smartTimepicker);

import smartUislider from './forms/input/smartUislider.directive';
SmartAdminDirectives.directive('smartUislider', smartUislider);

import smartXeditable from './forms/input/smartXeditable.directive';
SmartAdminDirectives.directive('smartXeditable', smartXeditable);

// Image editor

import smartJcrop from './forms/smartJcrop.directive';
SmartAdminDirectives.directive('smartJcrop', smartJcrop);

// Form layouts

import smartCheckoutForm from './forms/layouts/smartCheckoutForm.directive';
SmartAdminDirectives.directive('smartCheckoutForm', smartCheckoutForm);

import smartCommentForm from './forms/layouts/smartCommentForm.directive';
SmartAdminDirectives.directive('smartCommentForm', smartCommentForm);

import smartContactsForm from './forms/layouts/smartContactsForm.directive';
SmartAdminDirectives.directive('smartContactsForm', smartContactsForm);

import smartOrderForm from './forms/layouts/smartOrderForm.directive';
SmartAdminDirectives.directive('smartOrderForm', smartOrderForm);

import smartRegistrationForm from './forms/layouts/smartRegistrationForm.directive';
SmartAdminDirectives.directive('smartRegistrationForm', smartRegistrationForm);

import smartReviewForm from './forms/layouts/smartReviewForm.directive';
SmartAdminDirectives.directive('smartReviewForm', smartReviewForm);

// Editors

import smartCkEditor from './forms/editors/smartCkEditor.directive';
SmartAdminDirectives.directive('smartCkEditor',smartCkEditor);

import smartDestroySummernote from './forms/editors/smartDestroySummernote.directive';
SmartAdminDirectives.directive('smartDestroySummernote', smartDestroySummernote);

import smartEditSummernote from './forms/editors/smartEditSummernote.directive';
SmartAdminDirectives.directive('smartEditSummernote', smartEditSummernote);

import smartMarkdownEditor from './forms/editors/smartMarkdownEditor.directive';
SmartAdminDirectives.directive('smartMarkdownEditor', smartMarkdownEditor);

import smartSummernoteEditor from './forms/editors/smartSummernoteEditor.directive';
SmartAdminDirectives.directive('smartSummernoteEditor', smartSummernoteEditor);

// Bootstrap validation

import bootstrapAttributeForm from './forms/bootstrap-validation/bootstrapAttributeForm.directive';
SmartAdminDirectives.directive('bootstrapAttributeForm', bootstrapAttributeForm);

import bootstrapButtonGroupForm from './forms/bootstrap-validation/bootstrapButtonGroupForm.directive';
SmartAdminDirectives.directive('bootstrapButtonGroupForm', bootstrapButtonGroupForm);

import bootstrapContactForm from './forms/bootstrap-validation/bootstrapContactForm.directive';
SmartAdminDirectives.directive('bootstrapContactForm', bootstrapContactForm);

import bootstrapMovieForm from './forms/bootstrap-validation/bootstrapMovieForm.directive';
SmartAdminDirectives.directive('bootstrapMovieForm', bootstrapMovieForm);

import bootstrapProductForm from './forms/bootstrap-validation/bootstrapProductForm.directive';
SmartAdminDirectives.directive('bootstrapProductForm', bootstrapProductForm);

import bootstrapProfileForm from './forms/bootstrap-validation/bootstrapProfileForm.directive';
SmartAdminDirectives.directive('bootstrapProfileForm', bootstrapProfileForm);

import bootstrapTogglingForm from './forms/bootstrap-validation/bootstrapTogglingForm.directive';
SmartAdminDirectives.directive('bootstrapTogglingForm', bootstrapTogglingForm);

// Tables
import datatableBasic from './tables/datatableBasic';
SmartAdminDirectives.directive('datatableBasic', datatableBasic);

import datatableColumnFilter from './tables/datatableColumnFilter';
SmartAdminDirectives.directive('datatableColumnFilter', datatableColumnFilter);

import datatableColumnReorder from './tables/datatableColumnReorder';
SmartAdminDirectives.directive('datatableColumnReorder', datatableColumnReorder);

import datatableTableTools from './tables/datatableTableTools';
SmartAdminDirectives.directive('datatableTableTools', datatableTableTools);

export default SmartAdminDirectives;