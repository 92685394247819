/* @ngInject */
function BigThreeConfig($stateProvider) {
  $stateProvider
    .state('app.big-three', {
      url: '/big-three',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/big-three/big-three.html',
          controller: 'BigThreeCtrl',
          controllerAs: '$ctrl'
        }
      }
    });
}

export default BigThreeConfig;

