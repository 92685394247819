/* @ngInject */
function AppConfig($translateProvider) {

  $translateProvider.useStaticFilesLoader({
    prefix: '/locale/locale-',
    suffix: '.json'
  });

  $translateProvider.preferredLanguage('es');
}

export default AppConfig;
