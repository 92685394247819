
function TimestampToDate() {

  return function(timestamp, format) {

    let date = new Date(timestamp * 1000);
    return moment(date).format(format);
  }
}

export default TimestampToDate;
