'use strict';

var $ = require('jquery');

function minifyMenu(){
  return {
    restrict: 'A',
    link: function(scope, element){
      var $body = $('body');
      var minifyMenu = function() {
        if (!$body.hasClass("menu-on-top")) {
          $body.toggleClass("minified");
          $body.removeClass("hidden-menu");
          $('html').removeClass("hidden-menu-mobile-lock");
        }
      };

      element.on('click', minifyMenu);
    }
  }
}

export default minifyMenu;