/* @ngInject */
class LoginCtrl {
  constructor($http, $location, AppConstants, JWT, $state, $stateParams) {

    this._$http = $http;
    this._AppConstants = AppConstants;
    this._JWT = JWT;
    this._$state = $state;
    // Flags
    this.doingLogin = false;
    this.alreadyRegistered = false;

    this.account = {};

    this.errorMsg = '';
    this.errorCode = null;

    if ($location.hash() === 'validated') {
      this.validateMsg = 'Su cuenta ha sido validada, ya puede acceder.';
    } else if ($location.hash() === 'novalidated') {
      this.errorMsg = 'Usuario desconocido.';
    } else {
      this.validateMsg = '';
    }

    if ($stateParams.msg !== null)
      this.validateMsg = $stateParams.msg;
  }

  login() {
    this.errorMsg = '';
    this.errorCode = null;
    let url = `${this._AppConstants.api}/auth/login`;

    if(this.loginForm.$valid) {
      this.doingLogin = true;
      this._$http({
        method: 'POST',
        url: url,
        data: {
          email: this.account.email,
          password: this.account.password
        },
        skipAuthorization: true
      })
        .then((response) => {
          this.doingLogin = false;
          if(response.data.token){
            this._JWT.save(response.data.token);
            this._$state.go('app.dashboard');
          }else{
            console.log(response);
            if ( response.data.hasOwnProperty('error') && response.data.error ){
              if ( response.data.type === 'email_verification') {
                this.errorMsg = 'Aún no has verificado el correo electrónico';
                this.errorCode = response.data.type;
              } else if ( response.data.type === 'user_notfound') {
                this.errorMsg = 'Credenciales incorrectas.';
              } else {
                this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
              }
            }else{
              this.errorMsg = 'Credenciales incorrectas.';
            }
          }
        },() => {
          this.doingLogin = false;
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
        })
    }else{
      this.loginForm.email.$dirty = true;
      this.loginForm.password.$dirty = true;
    }
  }

  resendVerification(){
    this.errorMsg = '';
    this.errorCode = null;
    let url = `${this._AppConstants.api}/re-send-verification`;

    this.doingLogin = true;
    this._$http({
      method: 'POST',
      url: url,
      data: {
        email: this.account.email
      },
      skipAuthorization: true
    })
      .then((response) => {
        this.doingLogin = false;
        let resp = response.data;
        if ( resp.error ) {
          this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde';
        }else{
          this.validateMsg = 'Validación enviada. Por favor comprueba tu bandeja de entrada'
        }
      },() => {
        this.doingLogin = false;
        this.errorMsg = 'Ha ocurrido un error, por favor intentelo más tarde.';
      })
  }
}

export default LoginCtrl;