let authModule = angular.module('app.auth', []);

import authConfig from './auth.config';
authModule.config(authConfig);

// Real Login
import RealLoginCtrl from './real-login/realLogin.controller';
authModule.controller('RealLoginCtrl', RealLoginCtrl);

// Login
import LoginCtrl from './login/login.controller';
authModule.controller('LoginCtrl', LoginCtrl);

import loginInfo from './directives/login-info.directive';
authModule.directive('loginInfo', loginInfo);

// Password
import ForgotPasswordCtrl from './password/forgot-password.controller';
authModule.controller('ForgotPasswordCtrl', ForgotPasswordCtrl);

import ResetPasswordCtrl from './password/reset-password.controller';
authModule.controller('ResetPasswordCtrl', ResetPasswordCtrl);

// Register
import RegisterCtrl from './register/register.controller';
authModule.controller('RegisterCtrl', RegisterCtrl);

import RegisterHelpCtrl from './register-help/register-help.controller';
authModule.controller('RegisterHelpCtrl', RegisterHelpCtrl);

export default authModule;