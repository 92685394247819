'use strict';


function smartSelect2(lazyScript) {
  'ngInject';


  return {
    restrict: 'A',
    compile: function (element, attributes) {
      element.hide().removeAttr('smart-select2 data-smart-select2');
      lazyScript.register('build/vendor.ui.js').then(function(){
        element.show().select2();
      })
    }
  }
}

export default smartSelect2;