/* @ngInject */
function ProductosConfig($stateProvider) {
  $stateProvider
    .state('app.productos', {
      url: '/productos',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/productos/productos.html',
          controller: 'ProductosCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Productos: /* @ngInject */(Server) => {
          return Server.products().get().$promise;
        },
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.PRODUCTOS.PAGE_TITLE');
        }
      }
    });
}

export default ProductosConfig;

