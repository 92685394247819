'use strict';

var $ = require('jquery');

function smartNouislider($parse, lazyScript) {
  'ngInject';


  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      lazyScript.register('build/vendor.ui.js').then(function(){
        tElement.removeAttr('smart-nouislider data-smart-nouislider');

        tElement.addClass('noUiSlider');

        var options = {
          range: {
            min: tAttributes.rangeMin ? parseInt(tAttributes.rangeMin) : 0,
            max: tAttributes.rangeMax ? parseInt(tAttributes.rangeMax) : 1000
          },
          start: $parse(tAttributes.start)()
        };

        if (tAttributes.step) options.step =  parseInt(tAttributes.step);

        if(tAttributes.connect) options.connect = tAttributes.connect == 'true' ? true : tAttributes.connect;

        tElement.noUiSlider(options);

        if(tAttributes.update) tElement.on('slide', function(){
          $(tAttributes.update).text(JSON.stringify(tElement.val()));
        });
      })
    }
  }
}

export default smartNouislider;