/* @ngInject */
class ExampletCtrl {
  constructor() {

  }
}

let Example = {
  replace: true,
  transclude: true,
  controller: ExampletCtrl,
  templateUrl: 'addons/components/example.component.html'
};

/* @ngInject */
export default Example;