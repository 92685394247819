/* @ngInject */
function SettingsConfig($stateProvider) {
  $stateProvider
    .state('app.settings', {
      url: '/configuracion',
      parent: 'app',
      views: {
        'content': {
          templateUrl: 'screens/settings/settings.html',
          controller: 'SettingsCtrl',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        Datos: /* @ngInject */(Server) => {
          return Server.user().getConfig().$promise;
        },
        PAGETITLE: /* @ngInject */($translate) => {
          return $translate('PAGES.SETTINGS.PAGE_TITLE');
        }
      }
    });
}

export default SettingsConfig;

