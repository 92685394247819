/* @ngInject */
export default class JWT {

  constructor(AppConstants, $window, jwtHelper) {
    this._AppConstants = AppConstants;
    this._$window = $window;
    this._jwtHelper = jwtHelper;
    this.refreshTries = 3;
    this.isRefreshing = false;

    this.hasConnection = true;
  }

  setConnection(value) {
    this.hasConnection = value;
  }

  userHasConnection() {
    return this.hasConnection;
  }

  save(token) {
    this._$window.localStorage[this._AppConstants.jwtKey] = token;
  }

  get() {
    return this._$window.localStorage[this._AppConstants.jwtKey];
  }

  userHasToken() {
    let token = this.get();
    return typeof(token) !== 'undefined';
  }

  getPayload() {
    return this._jwtHelper.decodeToken(this.get());
  }

  getUserId() {
    return this._jwtHelper.decodeToken(this.get()).data.id;
  }

  destroy() {
    this._$window.localStorage.removeItem(this._AppConstants.jwtKey);
  }

  refreshAllowed(){
    if ( this.refreshTries > 0 && this.get() ){
      this.refreshTries--;
      this.isRefreshing = true;
      return true;
    }
    return false;
  }

  isTokenRefreshing(){
    return this.isRefreshing;
  }

  setRefreshing(value){
    this.isRefreshing = value;
  }

}
