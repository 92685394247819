'use strict';

function smartDuallistbox() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-duallistbox data-smart-duallistbox');


      var aOptions = _.pick(tAttributes, ['nonSelectedFilter']);

      var options = _.extend(aOptions, {
        nonSelectedListLabel: 'Non-selected',
        selectedListLabel: 'Selected',
        preserveSelectionOnMove: 'moved',
        moveOnSelect: false
      });

      tElement.bootstrapDualListbox(options);
    }
  }
}

export default smartDuallistbox;
