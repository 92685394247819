/**
 * Clase de entrada. En ocasiones puede cargarse antes que acaban de ejecutarse las tareas en el Run.
 * Aquí comprobaremos si hemos sincronizado la primera vez, si tenemos que bloquear por actualización...
 */
/* @ngInject */
class HomeCtrl {
  constructor($scope, $interval, CalendarEvent) {

    this._$state = $state;
    this._$scope = $scope;
  }
}

export default HomeCtrl;