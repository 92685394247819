/* @ngInject */
class LanguageCtrl {
  constructor( $translate, $filter, $cookies) {

    this._$translate = $translate;
    this._$cookies = $cookies;

    this.languages = [
      {
        key: 'es',
        name: 'Español'
      },{
        key: 'pt',
        name: 'Português'
      }
    ];

    let lang = $cookies.get('language');
    if ( typeof lang !== 'undefined' ){
      this.currentLanguageKey = lang;
    }else{
      this.currentLanguageKey = 'es';
    }
    this.currentLanguage = $filter('filter')(this.languages,{key: this.currentLanguageKey},true)[0];
  }

  selectLanguage(language){
    this.currentLanguage = language;

    this._$translate.use(language.key);
    this._$cookies.put('language', language.key);
  };
}

let LanguageSelector = {
  replace: true,
  transclude: true,
  controller: LanguageCtrl,
  templateUrl: 'addons/components/language.component.html'
};

export default LanguageSelector;