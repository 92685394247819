global.moment = require('moment');

require('angular');
require('angular-ui-router');
require('angular-jwt');
require('angular-resource');
require('angular-animate');
require('angular-cookies');
require('angular-aria');
require('angular-messages');
require('angular-sanitize');
require('angular-ui-bootstrap');
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-base64-upload');
require('ui-cropper');
require('ui-select');
require('restangular');

var $ = global.jQuery = require('jquery');
require('bootstrap');

import 'angular-sweetalert';
import 'sweetalert';
import AppRun from './config/app.run';

import AppConfig from './config/app.config';
import AppTranslationConfig from './config/app.translation-config';
import AppConstants from './config/app.constants';

// Import templates
import './config/app.templates';
// App functionality

import './SmartAdmin';

import './layout';
import './auth';

import './screens/home';
import './screens/dashboard';
import './screens/downloads';
import './screens/productos';
import './screens/promos';
import './screens/perfil';
import './screens/programas';
import './screens/ayuda';
import './screens/settings';
import './screens/big-three';

import './services';
import './addons/components';
import './addons/filters';
import './addons/directives';

// Specials
import './addons/ng-password-strength';

// App modules
const modules = [
  'ngResource',
  'ngAnimate',
  'ngAria',
  'ngPasswordStrength',
  'ngMessages',
  'ngCookies',
  'ngSanitize',
  'uiCropper',
  'restangular',
  'naif.base64',
  'angular-jwt',
  'ui.router',
  'ui.bootstrap',
  'ui.select',
  'pascalprecht.translate',
  'oitozero.ngSweetAlert',
  'SmartAdmin',
  'app.layout',
  'app.auth',
  'app.templates',
  'app.services',
  'app.components',
  'app.filters',
  'app.directives',
  // Modules
  'app.home',
  'app.dashboard',
  'app.downloads',
  'app.productos',
  'app.promos',
  'app.perfil',
  'app.programas',
  'app.ayuda',
  'app.settings',
  'app.big-three'
];


angular.module('app', modules);

angular.module('app').constant('AppConstants', AppConstants);

angular.module('app').config(AppConfig);
angular.module('app').config(AppTranslationConfig);

angular.module('app').run(AppRun);
