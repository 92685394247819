'use strict';

function smartEditSummernote() {
  return {
    restrict: 'A',
    compile: function (tElement, tAttributes) {
      tElement.removeAttr('smart-edit-summernote data-smart-edit-summernote');
      tElement.on('click', function(){
        angular.element(tAttributes.smartEditSummernote).summernote({
          focus : true
        });
      });
    }
  }
}

export default smartEditSummernote;