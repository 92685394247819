/* @ngInject */
function loginInfo(User){

  return {
    restrict: 'A',
    templateUrl: 'auth/directives/login-info.html',
    link: function(scope, element){
      User.initialized.then(function(){
        scope.user = User
      });
    }
  }
}

export default loginInfo;