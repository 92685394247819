/**
 * Clase de entrada. En ocasiones puede cargarse antes que acaban de ejecutarse las tareas en el Run.
 * Aquí comprobaremos si hemos sincronizado la primera vez, si tenemos que bloquear por actualización...
 */
/* @ngInject */
class BigThreeCtrl {
  constructor($scope, $state, $q, Server, AppConstants, Swal) {

    this._$state = $state;
    this._$scope = $scope;
    this._$q = $q;
    this._Server = Server;
    this._AppConstants = AppConstants;
    this._Swal = Swal;


    this.affectedProducts = [];
  }

  $onInit() {
    this._Server.bigThree().affectedProducts().$promise
      .then( (res) => {
        this.affectedProducts = res.data;
      });
  }

  getCode(product) {

    this._Swal.loading('Generando código...');
    this._Server.bigThree().createPromoCode({venta_id: product.venta_id}).$promise
      .then( (res) => {
        if ( res.error ) {
          this._Swal.customError(res.message);
        } else {
          this._Swal.close();
          const code = res.data;
          product.b3_code = code;
          window.location = `${this._AppConstants.api}/big-three-open/qr-code-pdf/${code}`;
        }
      })
  }
}

export default BigThreeCtrl;
