/**
 * Clase de entrada. En ocasiones puede cargarse antes que acaban de ejecutarse las tareas en el Run.
 * Aquí comprobaremos si hemos sincronizado la primera vez, si tenemos que bloquear por actualización...
 */
/* @ngInject */
class DownloadsCtrl {
  constructor($scope, $state, PAGETITLE, Descargas) {

    this._$state = $state;
    this._$scope = $scope;

    this.pageTitle = [PAGETITLE];

    this.data = Descargas;
    console.log(this.data);
  }
}

export default DownloadsCtrl;