class Swal {
  /*ngInject*/
  constructor(SweetAlert) {
    this._SweetAlert = SweetAlert;
  }

  error(type){

    let message = '';

    if ( typeof type === 'undefined' ){
      message = "Something were wrong";
    }

    if ( type === 'user_email_exists' ) {
      message = "Email already exists";
    }else if( type === 'max_users_reached' ) {
      message = "Max number of users reached";
    }else if( type === 'not_enough_credits' ) {
      message = "You don't have enough credits";
    }else if ( type === 'user_not_found' ){
      message = "User not found";
    }else if ( type === 'folder_already_exists' ){
      message = "Folder already exists";
    }else if ( type === 'folder_not_created' ){
      message = "Folder can not be created";
    }else if ( type === 'md5_not_found' ){
      message = "Something happens while saving file...";
    }else if ( type === 'file_not_saved' ){
      message = "Something happens while saving file...";
    }else if ( type === 'database_error' ){
      message = "Something happens while saving into database...";
    }else{
      message = "Something were wrong";
    }

    this._SweetAlert.swal("Error!", message, "error");
  }

  customError(message) {
    this._SweetAlert.swal("Error!", message, "error");
  }

  success(message) {
    this._SweetAlert.swal("Success!", message, "success");
  }

  loading(message) {
    this._SweetAlert.swal({
      title: message,
      text: 'Por favor, espera...',
      imageUrl: 'images/ajax-loader.gif',
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  input(title, message, callback){
    this._SweetAlert.swal({
      title: title,
      text: message,
      type: 'input',
      showCancelButton: true
    },(value) => {
      callback(value);
    });
  }

  ask(message, callback) {
    this._SweetAlert.swal({
        title: "Are you sure?",
        text: message,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false
      },
      (isConfirm) => {
        if ( isConfirm ) {
          callback();
        }
      });
  }

  question(title, message, confirmBtn, callback, notConfirmCallback) {
    this._SweetAlert.swal({
        title: title,
        text: message,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: confirmBtn,
        closeOnConfirm: false
      },
      (isConfirm) => {
        if ( isConfirm ) {
          callback();
        }else{
          notConfirmCallback();
        }
      });
  }

  close() {
    this._SweetAlert.close();
  }

}

export default Swal;

